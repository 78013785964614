<template>
  <section class="login-2">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div
          class="content-wrapper d-flex align-items-stretch auth auth-img-bg"
        >
          <div class="row flex-grow">
            <div
              class="col-lg-6 d-flex align-items-center justify-content-center"
            >
              <div class="auth-form-trans text-left p-3">
                <div class="brand-logo">
                  <img src="/img/logo.jpeg" alt="logo" />
                </div>
                <h4>Welcome back!</h4>
                <h6 class="font-weight-light">Happy to see you again!</h6>
                <form class="pt-3">
                  <div class="form-group">
                    <label for="exampleInputEmail">Username</label>
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      v-model="name"
                      :state="!!name"
                      aria-describedby="input-1-live-feedback"
                      @focus="closeValid"
                    ></b-form-input>
                  </div>

                  <div class="form-group password">
                    <label for="exampleInputPassword">Password</label>
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      v-model="pass"
                      :state="!!pass"
                      type="password"
                      aria-describedby="input-1-live-feedback"
                      @focus="closeValid"
                    ></b-form-input>
                  </div>
                  <b-form-invalid-feedback
                    id="input-5-live-feedback"
                    :class="{ active: invalid.value }"
                    v-show="invalid.value"
                  >
                    {{ invalid.text }}
                  </b-form-invalid-feedback>
                  <div class="my-3">
                    <button
                      class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      @click="login"
                      type="button"
                    >
                      LOGIN
                    </button>
                  </div>
                  <div class="text-center mt-4 font-weight-light">
                    Don't have an account?
                    <router-link to="/register" class="text-primary"
                      >Create</router-link
                    >
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 login-half-bg d-flex flex-row">
              <p
                class="text-white font-weight-medium text-center flex-grow align-self-end"
              >
                Copyright &copy; 2020 All rights reserved.
              </p>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      pass: null,
      invalid: {
        value: false,
        text: "",
      },
    };
  },
  computed: {
    required() {
      return this.name && this.pass;
    },
  },
  methods: {
    login() {
      if (!this.required) {
        this.invalid.value = true;
        this.invalid.text = "Please fill in all fields";
      } else {
        this.$axios
          .post("/login", {
            username: this.name,
            password: this.pass,
          })
          .then((res) => {
            this.$store.commit("updateToken", {token: res.data.access_token, refresh: res.data.refresh_token});
            
            const admin = res.data.admin ? '/admin' : '';
            this.$store.commit('updateAdmin', admin);
            localStorage.setItem('admin', admin);

            localStorage.setItem('username', this.name);

            this.$axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${localStorage.getItem("token")}`;

            this.$router.push({ name: "dashboard" });
          })
          .catch((err) => {
            this.invalid.value = true;
            this.invalid.text = JSON.parse(err.request.response).error;
          });
      }
    },
    closeValid() {
      this.invalid.value = false;
    },
  },
};
</script>

<style lang="scss">
#input-5-live-feedback {
  display: block !important;
  opacity: 0;
  height: 15px;
  &.active {
    opacity: 1;
  }
}
.login-2 {
  .form-group.password {
    margin-bottom: 0.5rem;
  }
  .form-control {
    padding: 10px;
    border: 1px solid #25378b;
  }
}
.auth {
  .auth-form-trans {
    width: 78%;
    margin: auto;
  }
}
</style>
